<template>
  <v-container class="pt-12 pt-lg-6 container" :class="{ 'shared-height': showActions }">
    <v-sheet class="socio">
      <CardSocio :showDetails="shouldShowDetails" :dataSocio="dataSocio">
        <v-card-text class="pa-0 mt-6">
          <div class="info-row">
            <v-icon class="mr-2">mdi-calendar-today</v-icon>
            <span class="info-label">Edad:</span>
            <span class="info-value">{{ dataSocio.edad }} ({{ dataSocio.fecha_nacimiento }})</span>
          </div>
          <div class="info-row">
            <v-icon class="mr-2">mdi-email</v-icon>
            <span class="info-label">Email:</span>
            <span class="info-value">{{ dataSocio.email }}</span>
          </div>
          <div class="info-row">
            <v-icon class="mr-2">mdi-cellphone-message</v-icon>
            <span class="info-label">Teléfono:</span>
            <span v-if="formatTelefono(dataSocio.telefono) != '-'" class="info-value"
              ><a :href="`https://wa.me/${dataSocio.telefono}`" target="_blank">{{ formatTelefono(dataSocio.telefono) }} <whats-app-icon /></a>
            </span>
            <span v-else class="info-value">-</span>
          </div>
        </v-card-text>
      </CardSocio>
      <ButtonsSocio v-show="estadosStore.shouldShow.mostrando" />
      <Overlay v-if="lgAndUp" :show="!estadosStore.shouldShow.mostrando"></Overlay>
    </v-sheet>
    <v-sheet class="actions" v-show="showActions">
      <Cobro ref="cobroRef" v-show="estadosStore.shouldShow.cobrando" />
      <BajaContrato ref="bajaRef" @switch="checkHabilitar" v-show="estadosStore.shouldShow.baja" />
      <Inscripcion ref="inscripcionRef" v-show="estadosStore.shouldShow.inscribiendo"></Inscripcion>
      <ConfirmationFooter :habilitado="ok" v-show="estadosStore.shouldShow.confirmando" @clickAction="handleAction" />
    </v-sheet>
  </v-container>
  <v-row justify="center">
    <v-dialog :model-value="estadosStore.shouldShow.editando" persistent scrollable width="1024" transition="dialog-bottom-transition">
      <FormularioSocio ref="formularioSocioRef" :dataSocio="dataSocio" />
    </v-dialog>
  </v-row>
</template>

<script setup>
  import { ref, computed, onMounted } from "vue"
  import { useDisplay } from "vuetify"
  import { useSociosStore, useEstadosStore, usePlanesStore, useCuotasStore } from "@/store"
  import { EMPTY_SOCIO, EDITANDO_SOCIO, INSCRIBIENDO_SOCIO, calcEdad, formatTelefono } from "@/helpers"
  import CardSocio from "../components/CardSocio.vue"
  import FormularioSocio from "../components/FormularioSocio.vue"
  import Inscripcion from "../components/Inscripcion.vue"
  import BajaContrato from "../components/BajaContrato.vue"
  import FAB from "../components/FAB.vue"
  import ConfirmationFooter from "../components/ConfirmationFooter.vue"
  import ButtonsSocio from "../components/ButtonsSocio.vue"
  import Cobro from "../components/Cobro.vue"
  import Overlay from "../components/Overlay.vue"
  import WhatsAppIcon from "../components/svg/whatsapp.vue"
  import { COBRANDO_SOCIO } from "../helpers/constants"
  import { BAJA_CONTRATO } from "../helpers/constants"

  const sociosStore = useSociosStore()
  const estadosStore = useEstadosStore()
  const planesStore = usePlanesStore()
  const cuotasStore = useCuotasStore()
  const { lgAndUp } = useDisplay()

  const dataSocio = computed(() => {
    // TODO: probably will be better to move all this to the store under socioSelected
    const data = sociosStore.socioSelected
    if (!data) {
      return EMPTY_SOCIO
    }
    return {
      id_socio: data.id_socio,
      nombre: data.nombre,
      apellido: data.apellido,
      nombrecompleto: `${data.nombre} ${data.apellido}`,
      dni: data.dni,
      email: data.email,
      telefono: data.telefono,
      fecha_nacimiento: data.fecha_nacimiento,
      edad: calcEdad(data.fecha_nacimiento), // TODO: create a calc edad function - maybe in dates.js
    }
  })

  const showDetails = ref(false)
  const shouldShowDetails = computed(() => {
    return showDetails.value && (estadosStore.shouldShow.editando || estadosStore.shouldShow.mostrando)
  })

  const showActions = computed(() => {
    return (
      estadosStore.shouldShow.cobrando || estadosStore.shouldShow.baja || estadosStore.shouldShow.inscribiendo || estadosStore.shouldShow.confirmando
    )
  })

  const ok = ref(false)
  const checkHabilitar = (value) => {
    ok.value = !!value
  }

  const formularioSocioRef = ref(null)
  const inscripcionRef = ref(null)
  const cobroRef = ref(null)
  const bajaRef = ref(null)
  const handleAction = (accept) => {
    if (accept) {
      switch (estadosStore.current_operation) {
        case EDITANDO_SOCIO:
          formularioSocioRef.value.saveSocioForm()
          showDetails.value = false
          break
        case INSCRIBIENDO_SOCIO:
          inscripcionRef.value.saveInscripcion()
          break
        case COBRANDO_SOCIO:
          cobroRef.value.cobroCuota()
          break
        case BAJA_CONTRATO:
          bajaRef.value.bajaContrato()
      }
    } else {
      showDetails.value = false
      cuotasStore.selectUnselectCuota(null)
      planesStore.selectUnselectPlan(null)
      estadosStore.setMostrandoSocio()
    }
  }

  onMounted(() => {
    sociosStore.getAllSocios()
  })
</script>
<style scoped>
  a {
    text-decoration: none;
    color: #4caf50;
    display: flex;
    gap: 8px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    max-width: 720px;
  }

  .shared-height {
    grid-template-rows: auto 1fr;
  }

  .lista-baja {
    margin-left: 16px;
    font-size: 1rem;
    color: hsl(33, 100%, 49%);
  }
  .socio {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .no-click {
    pointer-events: none;
  }

  .info-row {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 1280px) {
    .container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 1em;
      max-width: 1200px;
    }

    .actions {
      justify-content: space-between;
    }
  }
</style>
