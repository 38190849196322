<template>
  <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->
  <v-app-bar v-if="authStore.isLoggedIn" color="primary" density="compact" scroll-behavior="elevate">
    <v-app-bar-nav-icon variant="text" @click.stop="setRailDrawer"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ currentRoute() }}</v-toolbar-title>
    <SearchSocio :socios="socios"></SearchSocio>
  </v-app-bar>
  <v-app-bar v-else color="primary" density="compact"></v-app-bar>
  <v-navigation-drawer v-if="authStore.isLoggedIn" :model-value="drawer" :rail="rail" :railWidth="64">
    <template v-for="(item, index) in items" :key="index">
      <v-list-item :prepend-icon="item.icon" @click="handleItemClick(item)">
        {{ item.title }}
      </v-list-item>
      <v-divider v-if="item.divider" :thickness="2" color="info"></v-divider>
    </template>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn v-if="rail" icon="mdi-logout" @click="logOut" color="primary"></v-btn>
        <v-btn v-else prepend-icon="mdi-logout" @click="logOut" color="primary" block>Logout</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>
<script setup>
  import { ref, watch, onMounted } from "vue"
  import { useDisplay } from "vuetify"
  import { storeToRefs } from "pinia"
  import { MENU_ITEMS, DASHBOARD, SOCIOS, SOCIO_ALTA, PLANES, goToDashboard, goToSocios, goToSocioAlta, goToPlanes, currentRoute } from "@/helpers"
  import SearchSocio from "../components/SearchSocio"
  import { useAuthStore, useSociosStore } from "@/store"

  const { mobile } = useDisplay()

  const authStore = useAuthStore()
  const sociosStore = useSociosStore()
  const { socios } = storeToRefs(sociosStore)
  const drawer = ref(false)
  const rail = ref(false)

  onMounted(() => {
    console.log("MB",mobile.value)
    if (!mobile.value) {
      drawer.value = true
      rail.value = true
    }
  })

  const group = ref(null)
  const items = MENU_ITEMS

  // TODO: fix navbar being hide when click on icon
  const setRailDrawer = () => {
    if (mobile.value) {
      drawer.value = !drawer.value
      return
    }
    rail.value = !rail.value
    drawer.value = true
  }

  const handleItemClick = (item) => {
    if (mobile.value) {
      drawer.value = false
    }
    switch (item.value) {
      case DASHBOARD:
        goToDashboard()
        break
      case SOCIOS:
        goToSocios()
        break
      case SOCIO_ALTA:
        goToSocioAlta()
        break
      case PLANES:
        goToPlanes()
        break
    }
  }

  const logOut = () => {
    authStore.logout().catch((err) => {
      console.log(err, "upss seguis logueado")
    })
  }

  watch(group, (newGroup) => {
    drawer.value = false
  })
</script>
