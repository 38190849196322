import { defineStore } from "pinia"
import eventBus from '@/plugins/eventbus.js';
import { ALL_INGRESOS_TODAY_URL, fetchWrapper } from "@/helpers"
import { useSociosStore, useContratosStore } from "@/store"


export const useIngresosStore = defineStore("IngresosStore", {
  state: () => {
    return {
      ingresos: [],
      // ingreos_selected_id: null, tal vez
    }
  },

  getters: {
    ingresosToday() {
      const sociosStore = useSociosStore() 
      const contratosStore = useContratosStore() 
      return this.ingresos.map(ingreso =>{
        ingreso.nombrecompleto = `${sociosStore.socios_map[ingreso.id_socio]?.nombre}, ${sociosStore.socios_map[ingreso.id_socio]?.apellido}`
        ingreso.deuda = contratosStore.contratos_socios_presentes_map[ingreso.id_socio]?.tiene_deuda ?? false
        return ingreso
      })
    },
    sociosIds(){
      return Array.from(new Set(this.ingresos.map(ingreso =>ingreso.id_socio)))
    }
  },

  actions: {
    async getAllIngresosToday() {
      const response = await fetchWrapper.post(ALL_INGRESOS_TODAY_URL)
      if (response.result == true) {
        this.ingresos = response.ingresos
        // Emitir un evento
        eventBus.emit('mi-evento', this.sociosIds);
      }
    },
  },
})
