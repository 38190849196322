<template>
  <v-card class="overflow-visible bg-surface card-shadow rounded-lg px-4 py-5 v-card mt-8" outlined>
    <p class="user">Bienvenido {{ authStore.user?.username }}</p>
  </v-card>
  <v-card class="overflow-visible bg-surface card-shadow rounded-lg px-4 py-5 v-card mt-8" outlined title="Ingresos">
    <v-card-text class="pa-0">
      <v-table>
        <thead>
          <tr class="text-left text-high-emphasis">
            <th>Socio</th>
            <th>Hora</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(ingreso, index) in ingresosStore.ingresosToday" :key="ingreso.id_asistencia" :class="{'striped-row': index % 2 !== 0}" @click="selectSocio(ingreso.id_socio)">
            <td>{{ ingreso.nombrecompleto }}</td>
            <td>{{ formatHora(ingreso.hora) }}</td>
            <td><ChipDeudaIngresos :tiene_deuda="ingreso.deuda"/></td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>
<script setup>
  import { onMounted } from "vue"
  import { formatHora , goToSocios} from "@/helpers"
  import { useAuthStore, usePlanesStore, useSociosStore, useCuotasStore, useIngresosStore, useEstadosStore } from "@/store"
  import ChipDeudaIngresos from "@/components/ChipDeudaIngresos"
  

  const authStore = useAuthStore()
  const planesStore = usePlanesStore()
  const sociosStore = useSociosStore()
  const cuotasStore = useCuotasStore()
  const ingresosStore = useIngresosStore()
  const estadosStore = useEstadosStore()

  onMounted(() => {
    sociosStore.getAllSocios()
    planesStore.getAllPlanes()
    sociosStore.getAllSocios()
    cuotasStore.getActiveCuotas()
    ingresosStore.getAllIngresosToday()
  })

  const selectSocio = (socio_id) => {
    sociosStore.selectUnselectSocio(socio_id)
    estadosStore.setMostrandoSocio() // not sure if it should be here
    goToSocios()
  }
</script>
<style>
/*
  .v-table > .v-table__wrapper > table > thead > tr {
    background-color:hsl(221, 46%, 21%);
  } */
 
  .v-table > .v-table__wrapper > table {
    background-color:hsl(221, 46%, 26%);
  }

  .v-table > .v-table__wrapper > table > tbody > tr:hover {
    background-color:hsl(221, 46%, 31%);/* Color para las filas impares */
  }
</style>