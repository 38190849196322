import { createApp, markRaw } from "vue"
import { createPinia } from "pinia"
import { MOBILE_BREAKPOINT } from "./helpers"
import App from "./App.vue"
// import "./registerServiceWorker" TODO: investigar como usar ésto, por ahora dreprecado
import router from "./router"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

// Vuetify
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import "@mdi/font/css/materialdesignicons.css"
import colors from "vuetify/lib/util/colors"

import "./assets/css/styles.css"

const customDarkTheme = {
  dark: true,
  colors: {
    background: "#131819", // hsl(190, 14%, 9%)
    surface: "#16223b", // hsl(221, 46%, 16%)
    primary: "#3f51b5", // hsl(231, 48%, 48%)
    secondary: "#03dac6", // hsl(174, 97%, 43%)
    // secondary: "#9575cd", // hsl(261, 47%, 58%) - Un púrpura suave
    // secondary: "#00796b", // hsl(168, 100%, 24%) - Verde azulado oscuro
    // secondary: "#5c6bc0", // hsl(231, 47%, 58%) - Índigo más claro
    // secondary: "#fb8c00", // hsl(200, 18%, 47%) - Gris azulado
    error: "#f44336", // hsl(4, 90%, 58%)
    info: "#2196F3", // hsl(207, 90%, 54%)
    success: "#4caf50", // hsl(122, 39%, 49%)
    warning: "#fb8c00", // hsl(33, 100%, 49%)
  },
}

const vuetify = createVuetify({
  display: {
    mobileBreakpoint: MOBILE_BREAKPOINT,
    /*
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    }, */
  },
  components,
  directives,
  /*
  theme: {
    defaultTheme: "dark",
  } */
  theme: {
    defaultTheme: "customDarkTheme",
    themes: {
      customDarkTheme,
    },
  },
  options: {
    customProperties: true,
  },
})

const pinia = createPinia()

pinia.use(({ store }) => {
  store.$router = markRaw(router)
})

const isMobile = window.innerWidth < MOBILE_BREAKPOINT

const toastOptions = {
  position: isMobile ? "bottom-center" : "top-right",
  timeout: 5000,
  transition: "Vue-Toastification__fade",
  maxToasts: 5,
  newestOnTop: false,
}
console.log("NODE_ENV en Vue:", process.env.NODE_ENV)

// createApp(App).use(store).use(pinia).use(router).mount("#app")
createApp(App).use(pinia).use(vuetify).use(router).use(Toast, toastOptions).mount("#app")
