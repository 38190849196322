<template>
  <span v-show="!contratosStore.contrato_activo_socio?.tiene_deuda" class="info-value"
    ><v-chip class="ma-2" append-icon="mdi-check" color="green" text-color="white"> al Dia </v-chip></span
  >
  <span v-show="contratosStore.contrato_activo_socio?.tiene_deuda" class="info-value"
    ><v-chip class="ma-2" append-icon="mdi-alert-circle-outline" color="red" text-color="white"> con Deuda </v-chip></span
  >
</template>
<script setup>
  import { useContratosStore } from "@/store"

  const contratosStore = useContratosStore()
</script>
