<template>
  <div>
    <v-card outlined>
      <v-card-title> Confirmación de Baja </v-card-title>
      <v-card-text>
        <ul class="lista-baja">
          <li>El contrato se dará por finalizado.</li>
          <li class="mt-2">Se cancelará la última cuota no paga.</li>
          <li class="mt-2">El socio no podrá seguir accediendo a las instalaciones.</li>
        </ul>
      </v-card-text>
      <v-card-actions class="pa-4 d-flex justify-end actions">
        <div>
          <v-switch v-model="ok" :label="label" color="warning" value="OK" hide-details @change="$emit('switch', ok)"></v-switch>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script setup>
  import { ref, computed } from "vue"
  import { useToast } from "vue-toastification"
  import { useContratosStore, useEstadosStore } from "@/store/"
  import { OK } from "@/helpers/"

  const ok = ref(false)

  const label = computed(() => {
    return ok.value ? "OK" : "NO"
  })

  const contratosStore = useContratosStore()
  const estadosStore = useEstadosStore()
  const toast = useToast()

  const bajaContrato = async () => {
    const id_contrato = contratosStore.contrato_activo_socio.id_contrato
    const response = await contratosStore.bajaContrato(id_contrato)
    estadosStore.setMostrandoSocio()
    if (response.result == OK) {
      toast.success("Socio dado de baja correctamente", {
        timeout: 2000,
      })

      return true
    } else {
      toast.error("Ups.. hubo algún error", {
        timeout: 2000,
      })
      return false // Retorna false si hubo un error
    }
  }

  defineExpose({
    bajaContrato,
  })
</script>
<style scoped>
  .lista-baja {
    margin-left: 16px;
    font-size: 1rem;
    color: hsl(33, 100%, 49%);
  }
  .actions {
    background-color: hsl(200, 30%, 25%);
  }
</style>
