<template>
  <v-card class="overflow-visible bg-surface card-shadow rounded-lg px-4 py-5 v-card" outlined>
    <div class="bg-primary shadow-primary rounded-lg py-3 pe-1 mb-6 mt-n16 mt-lg-0">
      <v-card-title>
        <h3 class="headline mb-2">{{ dataSocio.nombrecompleto }}</h3>
        <p class="subheading mb-2">DNI: {{ dataSocio.dni }}</p>
      </v-card-title>
    </div>
    <v-card-text v-show="estadosStore.shouldShow.mostrando || estadosStore.shouldShow.editando" class="pa-0">
      <div class="info-row justify-space-between">
        <span>
          <v-icon class="mr-2">mdi-bank</v-icon>
          <span class="info-label">Pagos:</span>
          <ChipDeuda />
        </span>
        <v-btn
          color="secondary"
          variant="tonal"
          :icon="showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="showDetails = !showDetails"
        ></v-btn>
      </div>
    </v-card-text>
    <v-expand-transition>
      <div v-show="showDetails">
        <v-divider></v-divider>
        <slot></slot>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script setup>
  import { useEstadosStore } from "@/store"
  import ChipDeuda from "../components/ChipDeuda.vue"

  const estadosStore = useEstadosStore()

  const props = defineProps({ showDetails: { type: Boolean }, dataSocio: { type: Object } })
</script>

<style>
  .info-row {
    display: flex;
    /* align-items: center;  TODO: check with this */
    margin-bottom: 8px;
  }

  .info-label {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 8px;
  }

  .info-value {
    font-size: 1rem;
  }
</style>
