<template>
  <div class="v-col pa-0">
    <div class="pa-2">
      <v-btn :class="classtype" :prepend-icon="icon" stacked :variant="variant" :disabled="disabled" @click="handleClick">{{ text }}</v-btn>
    </div>
  </div>
</template>
<script setup>
  const props = defineProps(["icon", "text", "classtype", "disabled", "variant"])
  const emits = defineEmits(["click"])
  const handleClick = () => {
    if (!props.disabled) {
      emits("click")
    }
  }
</script>
<style scoped>
  .pa-2 {
    border-right: hsl(221, 46%, 16%) solid 1px;
    border-bottom: hsl(221, 46%, 16%) solid 1px;
  }

  .pa-2 button {
    width: 100%;
  }
  /*
    primary: "#3f51b5", hsl(231, 48%, 48%)
    secondary: "#03dac6",hsl(174, 97%, 43%)
    error: "#f44336",   hsl(4, 90%, 58%)
    info: "#2196F3",  	hsl(207, 90%, 54%)
    success: "#4caf50", hsl(122, 39%, 49%)
    warning: "#fb8c00", hsl(33, 100%, 49%)
    */

  .cobrar {
    background-color: hsl(122, 39%, 34%);
  }
  .cobrar:hover {
    background-color: hsl(122, 39%, 49%);
  }
  .inscribir {
    background-color: hsl(231, 48%, 35%);
  }
  .inscribir:hover {
    background-color: hsl(231, 48%, 48%);
  }
  .baja {
    background-color: hsl(4, 90%, 43%);
  }
  .baja:hover {
    background-color: hsl(4, 90%, 58%);
  }

  /*
  .nuevo {
    background-color: hsl(174, 97%, 43%);
  }
  .nuevo:hover {
    background-color: hsl(174, 97%, 53%); 
  }

  .editar {
    background-color: hsl(207, 90%, 54%); 
  }
  .editar:hover {
    background-color: hsl(207, 90%, 64%); 
  } */
</style>
