import { defineStore } from "pinia"
import { fetchWrapper, dateToDisplayFormat } from "@/helpers"
import { SAVE_SOCIO_URL, ALL_SOCIOS_URL, GET_SOCIO_URL } from "../helpers/constants"
import { useContratosStore, useCuotasStore } from "@/store"

export const useSociosStore = defineStore("SociosStore", {
  state: () => {
    return {
      socios_map: {},
      socio_selected_id: null,
    }
  },
  getters: {
    socios() {
      return Object.values(this.socios_map)
    },
    socioSelected() {
      if (this.socio_selected_id && this.socios) {
        const socio_original = this.socios_map[this.socio_selected_id]
        if (socio_original) {
          const socio = JSON.parse(JSON.stringify(socio_original))
          socio.fecha_nacimiento = dateToDisplayFormat(socio.fecha_nacimiento)

          return socio
        }
      }
      return null
    },
  },
  actions: {
    async getSocioById(id_socio) {
      const response = await fetchWrapper.post(GET_SOCIO_URL, { id_socio })
      if (response.result == true && response.socio.length > 0) {
        this.update_socio(response.socio[0])
      }
    },
    async getAllSocios() {
      const response = await fetchWrapper.get(ALL_SOCIOS_URL)
      if (response.result == true) {
        this.socios_map = response.socios.reduce((acc, socio) => {
          acc[socio.id_socio] = socio
          return acc
        }, {})
      }
    },
    async selectUnselectSocio(id) {
      if (id) {
        this.socio_selected_id = id
        const contratosStore = useContratosStore()
        await contratosStore.getActiveContractBySocioId(id)
        if (contratosStore.contrato_activo_socio) {
          const cuotasStore = useCuotasStore()
          cuotasStore.getCuotaByContratoId(contratosStore.contrato_activo_socio.id_contrato)
        }
      } else {
        this.socio_selected_id = null
      }
    },
    async saveSocio(socioToSave) {
      const response = await fetchWrapper.post(SAVE_SOCIO_URL, socioToSave)
      if (response.result == true) {
        // dejo ésto acá, porque siempre que guarde algo de los socios voy a querer actualizar la info del store
        // this.getAllSocios()
        this.getSocioById(response.id_socio)
      }
      return response
    },
    update_socio(socio) {
      this.$patch((state) => {
        state.socios_map[socio.id_socio] = socio
      })
    },
  },
})
