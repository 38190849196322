<template>
  <span v-show="!tiene_deuda" class="info-value"
    ><v-chip class="ma-2" color="green" text-color="white"> OK </v-chip></span
  >
  <span v-show="tiene_deuda" class="info-value"
    ><v-chip class="ma-2" color="red" text-color="white"> {{textoDeuda}} </v-chip></span
  >
</template>
<script setup>
  import { computed } from "vue"

  const props = defineProps({
    tiene_deuda: {
      type: Boolean,
      required: true,
    }
  })

  const textoDeuda = computed(() => {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth < 640) {
        return "D";
      } else {
        return "con Deuda";
      }
    }
  )
</script>
