<template>
  <v-text-field ref="fecha" label="Fecha de Nacimiento" readonly :rules="rules" :model-value="modelValue" @click.prevent="openDatePicker"></v-text-field>
  <VueDatePicker
    ref="datepicker"
    :value="modelValue"
    :enable-time-picker="false"
    locale="es"
    dark
    auto-apply
    :clearable="true"
    @update:modelValue="updateValue"
    :teleport="true"
  ></VueDatePicker>
</template>
<script setup>
  import { ref } from "vue"
  import VueDatePicker from "@vuepic/vue-datepicker"
  import "@vuepic/vue-datepicker/dist/main.css"
  import { dateToString } from "@/helpers/"

  const props = defineProps({
    modelValue: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    rules: {
      type: Object,
    }
  })

  const datepicker = ref(null)

  const openDatePicker = () => {
    if (!props.readonly) {
      datepicker.value.openMenu()
    }
  }

  // TODO: seems no need anymore, fixed with display none - check later
  // :alt-position="() => customPosition($refs.fecha)"
  const customPosition = (el) => {
    const rect = el.getBoundingClientRect()
    return { top: rect.top - 276 - 20, left: rect.left, transform: "translateY(10px)" }
  }

  const emit = defineEmits(["update:modelValue"])
  const updateValue = (dateValue) => {
    console.log("updateValue", dateValue); 
    emit("update:modelValue", dateToString(dateValue))
    // emit("update:modelValue", dateValue)
  }
</script>
<style>
  .dp__input_wrap {
    display: none;
  }
</style>
