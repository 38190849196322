<template>
  <div class="buttons-container">
    <div class="v-row flex-0-0 ma-0 main-buttons-container">
      <ButtonFancy
        v-show="showButtonCobrar"
        icon="mdi-cash-multiple"
        classtype="cobrar"
        text="Cobrar"
        :disabled="sociosStore.socio_selected_id == null"
        @click="estadosStore.setCobrando()"
      />
      <ButtonFancy
        v-show="showButtonInscribir"
        icon="mdi-weight-lifter"
        classtype="inscribir"
        text="Inscribir"
        :disabled="sociosStore.socio_selected_id == null"
        @click="estadosStore.setInscribiendo()"
      />
      <ButtonFancy
        v-show="showButtonDarBaja"
        icon="mdi-account-off"
        classtype="baja"
        text="Dar de baja"
        :disabled="sociosStore.socio_selected_id == null"
        @click="estadosStore.setBajaContrato()"
      />
    </div>
    <div class="v-row flex-0-0 ma-0 secondary-buttons-container">
      <ButtonFancy
        v-show="true"
        icon="mdi-account-off"
        variant="tonal"
        classtype="editar"
        text="Editar"
        :disabled="sociosStore.socio_selected_id == null"
        @click="estadosStore.setEditandoSocio()"
      />
    </div>
  </div>
</template>
<script setup>
  // TODO: remove edit and new..., add again the fav , add form to new socio
  import { computed } from "vue"
  import ButtonFancy from "../components/ButtonFancy.vue"
  import { useSociosStore, useEstadosStore } from "@/store"
  import { SocioEstado } from "../helpers/constants"
  const sociosStore = useSociosStore()
  const estadosStore = useEstadosStore()

  const showButtonCobrar = computed(() => {
    if (sociosStore.socioSelected) {
      if (sociosStore.socioSelected.estado == SocioEstado.ACTIVO) {
        return true
      }
      console.log("estado B", sociosStore.socioSelected.estado)
      return false
    }
    console.log("estado C", sociosStore.socioSelected)
    return true
  })

  const showButtonInscribir = computed(() => {
    if (sociosStore.socioSelected) {
      if (sociosStore.socioSelected.estado == SocioEstado.PENDIENTE_INSCRIPCION || sociosStore.socioSelected.estado == SocioEstado.INACTIVO) {
        return true
      }
      return false
    }
    return true
  })

  const showButtonDarBaja = computed(() => {
    if (sociosStore.socioSelected) {
      if (sociosStore.socioSelected.estado == SocioEstado.ACTIVO) {
        console.log("estado A", sociosStore.socioSelected.estado)
        return true
      }
      console.log("estado B", sociosStore.socioSelected.estado)
      return false
    }
    console.log("estado C", sociosStore.socioSelected)
    return true
  })
</script>
<style>
  .buttons-container {
    border-left: hsl(221, 46%, 16%) solid 1px;
    border-top: hsl(221, 46%, 16%) solid 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
</style>
