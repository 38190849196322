import { defineStore } from "pinia"
import {
  MOSTRANDO_SOCIO,
  CREANDO_SOCIO,
  EDITANDO_SOCIO,
  EDITANDO_PLAN,
  MOSTRANDO_PLAN,
  COBRANDO_SOCIO,
  INSCRIBIENDO_SOCIO,
  BAJA_SOCIO,
  BAJA_CONTRATO,
} from "@/helpers"

// TODO: check if this constants should be in the general file
// Definición de las constantes de operación

// Definición del store
export const useEstadosStore = defineStore({
  id: "EstadosStore", // this is another way to set de id
  state: () => ({
    current_operation: MOSTRANDO_SOCIO,
  }),
  getters: {
    // aca veo 2 opciones.., 1 estado para cada cosa, pero tal vez muy verboso (que le dicen) y la otra, arrancaré por ahí y si se complica luego separamos
    // tener estados generales.., que pueden ser compartidos de una pantalla a la otra
    shouldShow() {
      return {
        mostrando: this.current_operation == MOSTRANDO_SOCIO,
        editando: this.current_operation == EDITANDO_SOCIO,
        editando_plan: this.current_operation == EDITANDO_PLAN,
        creando_socio: this.current_operation == CREANDO_SOCIO,
        cobrando: this.current_operation == COBRANDO_SOCIO,
        inscribiendo: this.current_operation == INSCRIBIENDO_SOCIO,
        confirmando:
          this.current_operation == COBRANDO_SOCIO || this.current_operation == INSCRIBIENDO_SOCIO || this.current_operation == BAJA_CONTRATO,
        baja: this.current_operation == BAJA_CONTRATO,
      }
    },
  },
  actions: {
    setCurrentOperation(operation) {
      this.current_operation = operation
    },
    setCobrando() {
      this.current_operation = COBRANDO_SOCIO
    },
    setInscribiendo() {
      this.current_operation = INSCRIBIENDO_SOCIO
    },
    setEditandoSocio() {
      this.current_operation = EDITANDO_SOCIO
      // showDetails.value = true TODO: check this
    },
    setCreandoSocio() {
      this.current_operation = CREANDO_SOCIO
    },
    setMostrandoSocio() {
      this.current_operation = MOSTRANDO_SOCIO
    },
    setBajaContrato() {
      this.current_operation = BAJA_CONTRATO
    },
    setBajaSocio() {
      this.current_operation = BAJA_SOCIO
    },
    setEditandoPlan() {
      this.current_operation = EDITANDO_PLAN
    },
    setMostrandoPlan() {
      this.current_operation = MOSTRANDO_PLAN
    },
  },
})
