import { defineStore } from "pinia"
import { fetchWrapper, LOGIN_URL, RECOVERY_MAIL_URL, RESET_PASSWORD_URL, OK } from "@/helpers"

export const useAuthStore = defineStore("AuthStore", {
  state: () => {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      // user: null,
      return_url: null, // TODO: check if its being  used
    }
  },
  getters: {
    // computed prop of state
    isLoggedIn() {
      return this.user?.username ? true : false // TODO: check if will use this or what..
      // return true // for devlop
    },
  },
  actions: {
    async login(credentials) {
      console.log("login", credentials)
      const response = await fetchWrapper.post(LOGIN_URL, credentials)
      console.log(response)
      if (response.result == true) {
        // update pinia state
        this.user = response.user
        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(response.user))
        // redirect to previous url or default to home page
        console.log("router push")
        this.$router.push({ name: "Dashboard" })
      }

      return response
    },
    async logout() {
      this.user = null
      localStorage.removeItem("user")
      // location.reload()
      this.$router.push("/login")
    },
    async sendRecoveryPass(email) {
      return await fetchWrapper.post(RECOVERY_MAIL_URL, { email })
    },
    async resetPassword(password, passwordc, token) {
      return await fetchWrapper.post(RESET_PASSWORD_URL, { new: password, new_confirm: passwordc, token })
    },
  },
})
