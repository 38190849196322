import { useAuthStore } from "@/store/authStore"
import { BASE_URL } from "./constants"

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
}

function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    }
    if (body) {
      const body_url_format = format_url_encoded(body)

      // requestOptions.headers["Content-Type"] = "application/json" // NW
      requestOptions.headers["Content-Type"] = "application/x-www-form-urlencoded"
      // requestOptions.headers["Access-Control-Allow-Origin"] = "*"
      requestOptions.body = body_url_format
    }
    return fetch(url, requestOptions).then(handleResponse)
  }
}

// helper functions

function format_url_encoded(body_data) {
  console.log("body_data: ", body_data)

  let urlencoded = new URLSearchParams()
  for (let pair of Object.entries(body_data)) {
    urlencoded.append(pair[0], pair[1])
  }
  return urlencoded
}

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore()
  const isLoggedIn = !!user?.token
  // const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL)
  const isApiUrl = url.startsWith(BASE_URL)
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.token}` }
  } else {
    return {}
  }
}

/*
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      const { logout } = useAuthStore()
      if ([401, 403].includes(response.status)) {
        logout()
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
    return data
  })
}*/
function handleResponse(response) {
  return response
    .text()
    .then((text) => {
      const data = text && JSON.parse(text)
      if (!response.ok) {
        handleErrorResponse(response, data)
      }
      return data
    })
    .catch((error) => {
      console.error("Network error or server is down:", error)
      // Aquí puedes manejar el error, por ejemplo, mostrando un mensaje al usuario
      // o cambiando el estado de tu aplicación para reflejar que el servidor no está disponible.
      return Promise.reject("Network error or server is down")
    })
}

function handleErrorResponse(response, data) {
  const { logout } = useAuthStore()
  if ([401, 403].includes(response.status)) {
    logout()
  }
  const error = (data && data.message) || response.statusText
  return Promise.reject(error)
}
