// const PRODUCTION = "production"

// urls
// export const BASE_URL = process.env.process.env.VUE_APP_API_URL == PRODUCTION ? "https://g3.h2ocomplejodeportivo.com/api" : "http://localhost:8080/public/api"
// export const BASE_URL = "http://localhost:8080/public/api"

// export const BASE_URL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_BASE_URL : "http://localhost:8080/public/api"
export const MOBILE_BREAKPOINT = 1024
export const BASE_URL = process.env.VUE_APP_BASE_URL
export const LOGIN_URL = `${BASE_URL}/login`
export const RECOVERY_MAIL_URL = `${BASE_URL}/send_recovery_mail`
export const RESET_PASSWORD_URL = `${BASE_URL}/reset_password`
export const ALL_SOCIOS_URL = `${BASE_URL}/socios/get_all_socios`
export const SAVE_SOCIO_URL = `${BASE_URL}/socios/save`
export const GET_SOCIO_URL = `${BASE_URL}/socios/get_by`
export const ALL_PLANES_URL = `${BASE_URL}/planes/get_all_planes`
export const ALL_PLANES_ACTIVOS_URL = `${BASE_URL}/planes/get_all_planes_activos`
export const SAVE_PLAN_URL = `${BASE_URL}/planes/save`
export const SAVE_CONTRATO_URL = `${BASE_URL}/contratos/save`
export const BAJA_CONTRATO_URL = `${BASE_URL}/contratos/baja`
export const ACTIVE_CONTRATO_SOCIO_URL = `${BASE_URL}/contratos/get_contrato_activo_socio`
export const ACTIVE_CONTRATOS_SOCIOS_URL = `${BASE_URL}/contratos/get_contratos_activos_socios`
export const CUOTAS_CONTRATO_SOCIO_URL = `${BASE_URL}/lala` // TODO: set the right url here
export const CUOTAS_CONTRATOS_ACTIVOS_URL = `${BASE_URL}/contratocuotas/get_cuotas_contratos_activos`
export const COBRAR_CUOTA_URL = `${BASE_URL}/contratocuotas/cobrar_cuota`
export const GET_CUOTA_URL = `${BASE_URL}/contratocuotas/get_by`
export const ALL_INGRESOS_TODAY_URL = `${BASE_URL}/accesos/get_ingresos_today`

// states
// TODO: analizar desambiguación entre pendiente inscripcio vs pendiente pago
export const SocioEstado = Object.freeze({
  PENDIENTE_INSCRIPCION: 0, // el usuario fue dado de alta pero aún no tiene ningún contrato activo o pendiente
  ACTIVO: 1, // el usuario tiene un contrato activo o pendiente de pago
  DADO_DE_BAJA: 2, // el usuario no tiene contrato activo y expresó ser dado de baja y evitar ser contactado para futuras promos
  INACTIVO: 3, // el usuario no tiene contrato activo, finalizo su contrato con normalidad y no renovó
  EXPULSADO: 5, // el usuario no tiene contrato activo, y fue expulsado y no puede volver a menos que...
})

export const ContratoEstado = Object.freeze({
  PENDIENTE_PAGO: 0,
  ACTIVO: 1,
  FINALIZADO: 3,
})

export const CuotasEstado = Object.freeze({
  NO_PAGO: 0,
  PAGO: 1,
  ANULADA: 2,
})

export const PlanEstado = Object.freeze({
  INACTIVO: 0,
  ACTIVO: 1,
})

// route or view names -- not so sure about
export const LOGIN = "Login"
export const DASHBOARD = "Dashboard"
export const SOCIO_ALTA = "Socio alta"
export const SOCIOS = "Socios"
export const PLANES = "Planes"

export const MENU_ITEMS = [
  {
    title: "Dashboard",
    value: DASHBOARD,
    icon: "mdi-view-dashboard",
    divider: true,
  },
  {
    title: "Socios",
    value: SOCIOS,
    icon: "mdi-account-multiple ",
  },
  {
    title: "Socio Nuevo",
    value: SOCIO_ALTA,
    icon: "mdi-account-plus",
    divider: true,
  },
  {
    title: "Planes",
    value: PLANES,
    icon: "mdi-file-document-edit-outline",
  },
]

// result response
export const OK = true
export const ERROR = false

export const EMPTY_SOCIO = { socioId: "", nombrecompleto: "", dni: "", email: "", edad: "" }

export const CREANDO_SOCIO = 1
export const MOSTRANDO_SOCIO = 2
export const EDITANDO_SOCIO = 3
export const GUARDANDO_SOCIO = 4
export const COBRANDO_SOCIO = 10
export const INSCRIBIENDO_SOCIO = 11
export const BAJA_CONTRATO = 12
export const MOSTRANDO_PLAN = 22
export const EDITANDO_PLAN = 23
export const BAJA_SOCIO = 99
