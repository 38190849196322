<template>
  <v-container class="login-container">
    <v-row>
      <v-col cols="12" class="mt-8">
        <v-sheet class="pa-4">
          <h2>Acceso clientes</h2>
          <v-form ref="formRef" @submit.prevent="login" class="d-flex flex-column">
            <v-text-field class="mt-4" v-model="email" :rules="emailRules" label="Correo electrónico"></v-text-field>
            <v-text-field
              class="mt-4"
              v-model="password"
              :rules="passwordRules"
              label="Contraseña"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <p v-show="errorMessage" class="error-label">{{ errorMessage }}</p>
            <v-btn id="ingresar" type="submit" color="primary" class="mt-6">Ingresar</v-btn>
          </v-form>
          <!--
            <router-link class="d-block mt-6 text-center" :to="{ name: 'Reestablecer' }">¿No recuerdas tu contraseña?</router-link>
          -->
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
  import { ref, reactive } from "vue"
  import { useAuthStore } from "@/store"

  import { OK } from "@/helpers/"

  const email = ref("")
  const password = ref("")
  const authStore = useAuthStore()
  const errorMessage = ref("")
  const showPassword = ref(false)

  const showValidations = ref(false); 
  const serverErrors = reactive({});
  const formRef = ref(null)

  const emailRules = [
    (v) => {
        if (!showValidations.value) return true;
        if (serverErrors.email) {
          const error = serverErrors.email
          serverErrors.email = ''
          return error
        } 
        if (serverErrors.password && serverErrors.password == "Credenciales inválidas.") return serverErrors.password 
        if (!v) return "El correo electrónico es obligatorio."
        if ((v) && (/.+@.+\..+/.test(v)==false)) return "El correo electrónico debe contener un formato válido."
        if (v && v.length > 50) return "El correo electrónico no puede tener más de 50 caracteres."; 
        return true; 
      },
  ]

  const passwordRules = [
    (v) => {
        if (!showValidations.value) return true;
        if (serverErrors.password) {
          const error = serverErrors.password
          serverErrors.password = ''
          return error
        } 
        if (!v) return "El password es obligatorio."
        if (v && v.length > 256) return "El password no puede tener más de 256 caracteres."; 
        return true; 
      },
  ]


  const login = () => {
    localStorage.setItem("testLS", JSON.stringify({ ls: "working" }))
    authStore
      .login({ email: email.value, password: password.value })
      .then((response) => {
        if (response.result != OK){
          Object.assign(serverErrors, response.errors);
          showValidations.value = true;
          formRef.value.validate()
        } 
      })
      .catch((err) => {
        console.log(err, "wrong data")
      })
  }
</script>
<style scoped>
  .login-container {
    max-width: 360px;
  }

  @media (min-width: 720px) {
    .login-container {
      max-width: 620px;
    }
  }
</style>
