import { defineStore } from "pinia"
import { fetchWrapper, CUOTAS_CONTRATOS_ACTIVOS_URL, COBRAR_CUOTA_URL, GET_CUOTA_URL, ContratoEstado } from "@/helpers"
import { useContratosStore, useSociosStore } from "@/store"

export const useCuotasStore = defineStore("CuotasStore", {
  state: () => {
    return {
      cuotas_map: {},
      cuota_selected_id: null,
    }
  },
  getters: {
    cuotas() {
      // return Object.values(this.cuotas_map)
      return Object.values(this.cuotas_map).reduce((acc, cuotasArray) => {
        return [...acc, ...cuotasArray]
      }, [])
    },
    cuotaSelected() {
      if (this.cuota_selected_id && this.cuotas) {
        return this.cuotas.find((el) => el.id_contrato_cuota == this.cuota_selected_id)
      }
      return null
    },
    // TODO: here I see 2 options..,
    // (1) get all cuotas of active contracts .. and then do the search here
    // (2) ask to the DB everytime a diferen socio is selected,
    // by now will follow the first one... need to review later.
    cuotasSocio() {
      // acá tenemos en cuenta que a lo sumo habrá 2 cuotas activas, la actual y la futura siguiente
      const contratosStore = useContratosStore()
      if (this.cuotas) {
        if (contratosStore.contrato_activo_socio) {
          let cuotas_filtradas = this.cuotas.filter((el) => el.id_contrato == contratosStore.contrato_activo_socio.id_contrato)
          cuotas_filtradas.sort((a, b) => parseInt(a.id_contrato_cuota) - parseInt(b.id_contrato_cuota))
          let ultimas_cuotas = cuotas_filtradas.slice(-2)
          ultimas_cuotas = ultimas_cuotas.map((cuota, index, array) => {
            cuota.es_ultima = index === array.length - 1 // La última cuota en el array es la última cuota
            return cuota
          })
          return ultimas_cuotas
        }
      }
      return null
    },
  },

  actions: {
    selectUnselectCuota(id) {
      if (id) {
        this.cuota_selected_id = id
      } else {
        this.cuota_selected_id = null
      }
    },
    async getCuotaByContratoId(id_contrato) {
      const response = await fetchWrapper.post(GET_CUOTA_URL, { id_contrato })
      if (response.result == true && response.contrato_cuotas.length > 0) {
        this.update_cuotas(id_contrato, response.contrato_cuotas)
      }
    },
    async cobrarCuota(cuotaData) {
      const response = await fetchWrapper.post(COBRAR_CUOTA_URL, cuotaData)
      if (response.result == true) {
        this.getCuotaByContratoId(cuotaData.id_contrato)
        const sociosStore = useSociosStore()
        sociosStore.getSocioById(sociosStore.socio_selected_id)
        const contratosStore = useContratosStore()
        contratosStore.getActiveContractBySocioId(sociosStore.socio_selected_id)
      }
      return response
    },
    async getActiveCuotas() {
      const estado = [ContratoEstado.PENDIENTE_PAGO, ContratoEstado.ACTIVO]
      const response = await fetchWrapper.post(CUOTAS_CONTRATOS_ACTIVOS_URL, { estado })
      if (response.result == true) {
        this.cuotas_map = response.cuotas.reduce((acc, cuota) => {
          acc[cuota.id_contrato] = [...(acc[cuota.id_contrato] || []), cuota]
          return acc
        }, {})
      }
    },
    update_cuotas(id_contrato, contrato_cuotas) {
      this.$patch((state) => {
        state.cuotas_map[id_contrato] = contrato_cuotas
      })
    },
  },
})
