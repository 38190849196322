<template>
  <v-container>
    <v-card class="overflow-visible bg-surface card-shadow rounded-lg px-4 py-5 v-card mt-8" outlined>
      <v-row>
        <v-col cols="12" sm="6" md="6" v-for="plan in planes" :key="plan.id_plan">
          <CardPlan :plan="plan" :selectedPlanId="planesStore.plan_selected_id" :showDetails="estadosStore.shouldShow.editando">
            <template v-slot:edit>
              <v-btn @click="selectPlan(plan.id_plan)" color="secondary" variant="tonal" icon="mdi-pencil"></v-btn>
            </template>
            <template v-slot:form>
              <FormularioPlan ref="formularioPlanRefs" :editing="estadosStore.shouldShow.editando_plan" :dataPlan="plan"></FormularioPlan>
            </template>
          </CardPlan>
          <ConfirmationFooter v-show="estadosStore.shouldShow.confirmando" @clickAction="handleAction" />
        </v-col>
      </v-row>
    </v-card>
    <v-dialog :model-value="estadosStore.shouldShow.editando_plan" persistent scrollable width="1024" transition="dialog-bottom-transition">
      <FormularioPlan ref="formularioPlanRefs" :editing="estadosStore.shouldShow.editando_plan" :dataPlan="planesStore.planSelected"></FormularioPlan>
    </v-dialog>
  </v-container>
</template>

<script setup>
  import { ref, onMounted, computed } from "vue"
  import { usePlanesStore, useEstadosStore } from "@/store"
  import CardPlan from "@/components/CardPlan"
  import { MOSTRANDO_PLAN, EDITANDO_PLAN } from "@/helpers"
  import ConfirmationFooter from "../components/ConfirmationFooter.vue"
  import FormularioPlan from "../components/FormularioPlan.vue"

  const estadosStore = useEstadosStore()

  const planesStore = usePlanesStore()

  onMounted(async () => {
    estadosStore.current_operation = MOSTRANDO_PLAN
    await planesStore.getAllPlanes()
  })

  const selectPlan = (id) => {
    planesStore.selectUnselectPlan(id)
    estadosStore.setEditandoPlan()
  }

  const planes = computed(() => {
    return planesStore.activePlanes
  })

  const formularioPlanRefs = ref([])

  const handleAction = (accept) => {
    if (accept) {
      const index = planes.value.findIndex((p) => p.id_plan === planesStore.plan_selected_id)
      switch (estadosStore.current_operation) {
        case EDITANDO_PLAN:
          if (index !== -1 && formularioPlanRefs.value[index]) {
            formularioPlanRefs.value[index].savePlanForm()
          }
          break
      }
    } else {
      planesStore.selectUnselectPlan(null)
      estadosStore.setMostrandoPlan()
    }
  }

  // TODO: allow to create a plan, activate it, deactivate it and change his price.
  // -- for now will allow only to modify prices
</script>
