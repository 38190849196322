import { defineStore } from "pinia"
import { fetchWrapper } from "@/helpers"
import { ALL_PLANES_URL, ALL_PLANES_ACTIVOS_URL, SAVE_PLAN_URL } from "../helpers/constants"

export const usePlanesStore = defineStore("PlanesStore", {
  state: () => {
    return {
      planes: [],
      plan_selected_id: null,
    }
  },
  getters: {
    // computed prop of state
    planSelected() {
      if (this.plan_selected_id && this.activePlanes) {
        return this.activePlanes.find((el) => el.id_plan == this.plan_selected_id)
      }
      return null
    },
    activePlanes() {
      const today = new Date()
      return this.planes.filter((plan) => {
        const startDate = new Date(plan.fecha_inicio)
        return plan.estado === "1" && startDate <= today && plan.fecha_fin == null // la fecha es un chequeo extra en teoría todo plan plan activo tiene que estar con la fecha en rango
      })
    },
    currentPlanes() {
      const today = new Date()
      return this.planes.filter((plan) => {
        return plan.fecha_fin == null // la fecha es un chequeo extra en teoría todo plan plan activo tiene que estar con la fecha en rango
      })
    },
  },
  actions: {
    async getAllPlanes() {
      const response = await fetchWrapper.get(ALL_PLANES_ACTIVOS_URL)
      if (response.result == true) {
        this.planes = response.planes
      }
    },
    selectUnselectPlan(id) {
      if (id) {
        this.plan_selected_id = id
      } else {
        this.plan_selected_id = null
      }
    },
    async savePlan(planToSave) {
      const response = await fetchWrapper.post(SAVE_PLAN_URL, planToSave)
      if (response.result == true) {
        this.getAllPlanes()
      }
      return response
    },
  },
})
