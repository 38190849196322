<template>
  <!-- 
    TODO: probably will need better CSS, to be fixed to bottom, maybe <v-bottom-navigation>
    TODO: if I add d-flex it reescribes the v-show .. lpm
    -->
  <footer class="bg-background action-footer pa-4">
    <div class="d-flex justify-space-between">
      <v-btn value="cancel" color="error" @click="$emit('clickAction', false)">
        <v-icon>mdi-cancel</v-icon>
        Cancelar
      </v-btn>
      <v-btn :disabled="aceptarDisabled" value="accept" color="primary" @click="$emit('clickAction', true)">
        <v-icon>mdi-check</v-icon>
        Aceptar
      </v-btn>
    </div>
  </footer>
</template>
<script setup>
  import { computed } from "vue"
  import { INSCRIBIENDO_SOCIO, COBRANDO_SOCIO, BAJA_CONTRATO } from "@/helpers"
  import { useEstadosStore, usePlanesStore, useCuotasStore } from "@/store"

  const props = defineProps({
    habilitado: {
      type: Boolean,
      default: true,
    },
  })

  const estadosStore = useEstadosStore()
  const planesStore = usePlanesStore()
  const cuotasStore = useCuotasStore()

  const aceptarDisabled = computed(() => {
    switch (estadosStore.current_operation) {
      case INSCRIBIENDO_SOCIO:
        if (planesStore.planSelected == null) {
          return true
        }
        break
      case COBRANDO_SOCIO:
        if (cuotasStore.cuotaSelected == null) {
          return true
        }
        break
      case BAJA_CONTRATO:
        if (!props.habilitado) {
          return true
        }
        break
    }
    return false
  })
</script>
<style>
  .action-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    .action-footer {
      position: relative;
    }
  }
</style>
