<template>
  <v-card class="bg-background mt-8">
    <v-card-title>
      <span class="text-h6">Editando Plan</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="formRef" >
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="localPlan.nombre_plan" :rules="nameRules" label="Nombre" required :readonly="!editing"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="localPlan.descripcion" label="Descripcion" :readonly="!editing" clearable></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="localPlan.precio" :rules="precioRules" label="Precio" required :readonly="!editing"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <div class="buttons py-4 pa-sm-6 d-flex justify-space-between">
      <v-btn value="cancel" color="error" @click="cancel">
        <v-icon>mdi-cancel</v-icon>
        Cancelar
      </v-btn>
      <v-btn value="accept" color="primary" @click="savePlanForm">
        <v-icon>mdi-check</v-icon>
        Aceptar
      </v-btn>
    </div>
  </v-card>
</template>
<script setup>
  import { ref, reactive, watch } from "vue"
  import { useToast } from "vue-toastification"
  import { usePlanesStore, useEstadosStore } from "@/store"
  import { OK } from "@/helpers"

  const props = defineProps({
    editing: {
      type: Boolean,
      required: true,
    },
    dataPlan: {
      type: Object,
    },
  })

  const localPlan = reactive(Object.assign({}, props.dataPlan))

  watch(
    () => props.dataPlan,
    () => {
      Object.assign(localPlan, props.dataPlan)
    }
  )
  
  const showValidations = ref(false); 
  const serverErrors = reactive({});
  const formRef = ref(null);
  
  const nameRules = [
    (v) => {
      if (!showValidations.value) return true; 
      if (serverErrors.nombre_plan) {
        const error = serverErrors.nombre_plan
        serverErrors.nombre_plan = ''
        return error
      } 
      if (!v) return "El nombre del plan es obligatorio."; 
      if (v.length > 100) return "El nombre del plan no puede tener más de 100 caracters."; 
      return true; 
    },
  ]
  
  const precioRules = [
    (v) => {
      if (!showValidations.value) return true; 
      if (serverErrors.precio) {
        const error = serverErrors.precio
        serverErrors.precio = ''
        return error
      } 
      if (!v) return "El precio del plan es obligatorio."; 
      if (/^\d+$/.test(v)==false) return "El precio debe ser un número entero mayor que 0."; 
      if (v<1) return "El precio debe ser un número entero mayor que 0."; 
      return true; 
    },
  ]
  const planesStore = usePlanesStore()
  const estadosStore = useEstadosStore()
  const toast = useToast()

  const cancel = () => {
    estadosStore.setMostrandoPlan()
  }

  const savePlanForm = async () => {
    showValidations.value = false; 
    const response = await planesStore.savePlan(localPlan)
    if (response.result == OK) {
      toast.success("Plan guardado correctamente", {
        timeout: 2000,
      })
      Object.keys(serverErrors).forEach(key => serverErrors[key] = '');
      estadosStore.setMostrandoPlan()
      return true
    } else {
      Object.assign(serverErrors, response.errors);
      showValidations.value = true;
      formRef.value.validate()
      toast.error("Ups.. hubo algún error", {
        timeout: 2000,
      })
      return false // Retorna false si hubo un error
    }
  }

  defineExpose({
    savePlanForm,
  })
</script>
