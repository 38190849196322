import { defineStore } from "pinia"
import eventBus from '@/plugins/eventbus.js';
import { fetchWrapper } from "@/helpers"
import { ACTIVE_CONTRATO_SOCIO_URL, ACTIVE_CONTRATOS_SOCIOS_URL,  SAVE_CONTRATO_URL, BAJA_CONTRATO_URL } from "../helpers/constants"
import { useSociosStore, useCuotasStore } from "@/store"

export const useContratosStore = defineStore("ContratosStore", {
  state: () => {
    return {
      contrato_activo_socio: null,
      contratos_socios_presentes_map: []
    }
  },
  actions: {
    /*  TODO: here probably should get 
    async getAllContratos() {
      const response = await fetchWrapper.get(ALL_CONTRACTOS_URL)
      if (response.result == true) {
        this.contratos = response.contratos
      }
    }, */
   
    async saveContrato(contratoToSave) {
      const response = await fetchWrapper.post(SAVE_CONTRATO_URL, contratoToSave)
      if (response.result == true) {
        const sociosStore = useSociosStore()
        const id_socio = sociosStore.socio_selected_id
        await sociosStore.getSocioById(id_socio)
        this.getActiveContractBySocioId(id_socio)
        const cuotasStore = useCuotasStore()
        cuotasStore.getCuotaByContratoId(response.id_contrato)
      }
      return response
    },
    async getActiveContractBySocioId(id_socio) {
      const response = await fetchWrapper.post(ACTIVE_CONTRATO_SOCIO_URL, { id_socio })
      if (response.result == true) {
        this.contrato_activo_socio = response.contrato
      }
    },
    async getActiveContractsBySociosIds(ids_socios) {
      // const response = await fetchWrapper.post(ACTIVE_CONTRATOS_SOCIOS_URL, { ids_socios })
      const response = await fetchWrapper.post(ACTIVE_CONTRATOS_SOCIOS_URL, { ids_socios })
      if (response.result == true) {
        this.contratos_socios_presentes_map = response.contratos.reduce((acc, contrato) => {
          acc[contrato.id_socio] = contrato
          return acc
        }, {})

      } 
    },
    async bajaContrato(id_contrato) {
      const response = await fetchWrapper.post(BAJA_CONTRATO_URL, { id_contrato })
      if (response.result == true) {
        const sociosStore = useSociosStore()
        const id_socio = sociosStore.socio_selected_id
        await sociosStore.getSocioById(id_socio)
        this.getActiveContractBySocioId(id_socio)
        const cuotasStore = useCuotasStore()
        cuotasStore.getCuotaByContratoId(response.id_contrato)
      }
      return response
    },
  },

   
      

})

// Escuchar un evento y usar una acción del contratosStore
eventBus.on('mi-evento', (data) => {
  // Usar la acción del store
  console.log('el evento', data)
  const store = useContratosStore()
  store.getActiveContractsBySociosIds(data)

})

