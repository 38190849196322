<template>
  <div class="px-4 pb-8">
    <v-banner lines="one" :stacked="false">
      <v-text-field class="amount-to-pay" readonly label="Monto a pagar:" variant="underlined" v-model="formattedAmount"></v-text-field>
    </v-banner>
    <div class="cuotas-container">
      <template v-for="cuota in cuotasStore.cuotasSocio" :key="cuota.id_contrato_cuota">
        <CardCuota :cuota="cuota" />
      </template>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed, watch } from "vue"
  import { useToast } from "vue-toastification"
  import CardCuota from "../components/CardCuota.vue"
  import { useCuotasStore, useEstadosStore } from "@/store"
  import { OK } from "@/helpers"

  const cuotasStore = useCuotasStore()
  const amount = computed(() => {
    if (cuotasStore.cuotaSelected) {
      return cuotasStore.cuotaSelected.precio
    }
    return 0
  })

  // Nuevo ref para el monto formateado
  const formattedAmount = ref("")

  // Función para formatear el precio
  const formatPrice = (price) => {
    return new Intl.NumberFormat("es-AR", { style: "currency", currency: "ARS", minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(price)
  }

  // Observa cambios en cuotasStore.cuotaSelected
  watch(
    amount,
    (newAmount) => {
      formattedAmount.value = formatPrice(newAmount)
    },
    { immediate: true }
  )

  const estadosStore = useEstadosStore()
  const toast = useToast()
  const cobroCuota = async () => {
    // data cuota
    const cuotaData = {
      id_contrato_cuota: cuotasStore.cuotaSelected.id_contrato_cuota,
      id_contrato: cuotasStore.cuotaSelected.id_contrato,
    }
    const response = await cuotasStore.cobrarCuota(cuotaData)
    estadosStore.setMostrandoSocio() // TODO: check if should let this .. or still "cobrando , no changes"
    if (response.result == OK) {
      toast.success("Cuota cobrada correctamente", {
        timeout: 2000,
      })
      return true
    } else {
      toast.error("Ups.. hubo algún error", {
        timeout: 2000,
      })
      return false // Retorna false si hubo un error
    }
  }

  defineExpose({
    cobroCuota,
  })
</script>
<style scoped>
  .v-text-field >>> input {
    font-size: 1.5em;
    font-weight: 100;
    text-transform: capitalize;
    text-align: center;
  }
  .v-text-field >>> label {
    font-size: 1.1em;
  }

  .cuotas-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  @media only screen and (min-width: 768px) {
    .cuotas-container {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media only screen and (min-width: 1280px) {
    .cuotas-container {
      grid-template-columns: 1fr;
    }
  }
</style>
