<template>
  <v-row justify="center">
    <v-dialog :model-value="estadosStore.shouldShow.creando_socio" persistent scrollable width="1024" transition="dialog-bottom-transition">
      <FormularioSocio />
    </v-dialog>
  </v-row>
</template>

<script setup>
  import { onMounted } from "vue"
  import { useEstadosStore } from "@/store"
  import FormularioSocio from "../components/FormularioSocio.vue"

  const estadosStore = useEstadosStore()

  onMounted(() => {
    estadosStore.setCreandoSocio()
  })
</script>
