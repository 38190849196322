<template>
  <v-btn icon @click="dialog = true">
    <v-icon>mdi-magnify</v-icon>
  </v-btn>

  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" label="Buscar" clearable></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="(socio, index) in limited_socios" :key="index" @click="selectSocio(socio)">
            {{ socio.title }}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
  import { ref, computed, defineProps } from "vue"
  import { useSociosStore, useEstadosStore } from "@/store"
  import { goToSocios, normalizeString } from "@/helpers"

  const props = defineProps({
    socios: {
      type: Array,
      required: true,
    },
  })

  const dialog = ref(false)
  const search = ref("")
  const socio_id_selected = ref(null)

  const all_socios = computed(() => {
    return props.socios.map((socio) => {
      return { title: `(${socio.dni}) - ${socio.nombre}, ${socio.apellido}`, value: `${socio.id_socio}` }
    })
  })
  /*
  const filtered_socios = computed(() => {
    if (!search.value) {
      return all_socios.value
    }
    return all_socios.value.filter((socio) => socio.title.toLowerCase().includes(search.value.toLowerCase()))
  }) */

  const filtered_socios = computed(() => {
    if (!search.value) {
      return all_socios.value;
    }
    const normalizedSearch = normalizeString(search.value);
    return all_socios.value.filter((socio) => normalizeString(socio.title).includes(normalizedSearch));
  });

  const limited_socios = computed(() => {
    return filtered_socios.value.slice(0, 10)
  })

  const sociosStore = useSociosStore()
  const estadosStore = useEstadosStore()

  const selectSocio = (socio) => {
    socio_id_selected.value = socio.value
    sociosStore.selectUnselectSocio(socio_id_selected.value)
    dialog.value = false
    estadosStore.setMostrandoSocio() // not sure if it should be here
    goToSocios()
  }
</script>
