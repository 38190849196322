<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-for="plan in planes" :key="plan.id_plan">
        <CardPlan @click="selectPlan(plan.id_plan)" :plan="plan" :selectedPlanId="planesStore.plan_selected_id"></CardPlan>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { ref, onMounted } from "vue"
  import { useToast } from "vue-toastification"
  import { usePlanesStore, useContratosStore, useSociosStore, useEstadosStore } from "@/store"
  import { OK } from "@/helpers"
  import CardPlan from "@/components/CardPlan"

  const planesStore = usePlanesStore()
  let planes = ref([])

  onMounted(async () => {
    await planesStore.getAllPlanes()
    planes.value = planesStore.activePlanes
  })

  const selectPlan = (id) => {
    planesStore.selectUnselectPlan(id)
    // Aquí puedes hacer cualquier otra cosa que necesites después de seleccionar un plan
  }

  const contratosStore = useContratosStore()
  const sociosStore = useSociosStore()
  const toast = useToast()
  const estadosStore = useEstadosStore()

  const saveInscripcion = async () => {
    const contratoToSend = {
      id_socio: sociosStore.socio_selected_id,
      id_plan: planesStore.plan_selected_id,
    }
    const response = await contratosStore.saveContrato(contratoToSend)
    estadosStore.setMostrandoSocio()
    if (response.result == OK) {
      toast.success("Socio inscripto correctamente", {
        timeout: 2000,
      })

      return true
    } else {
      toast.error("Ups.. hubo algún error", {
        timeout: 2000,
      })
      return false // Retorna false si hubo un error
    }
  }

  defineExpose({
    saveInscripcion,
  })
</script>
