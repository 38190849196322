import router from "@/router"
import { DASHBOARD, LOGIN, SOCIOS, PLANES, SOCIO_ALTA } from "./constants"

export function goToLogin() {
  router.push({ name: LOGIN })
}

export function goToDashboard() {
  router.push({ name: DASHBOARD })
}

export function goToSocios() {
  router.push({ name: SOCIOS })
}

export function goToSocioAlta() {
  router.push({ name: SOCIO_ALTA })
}

export function getRouterToken() {
  const token = router.currentRoute._value?.params?.token ?? null
  return token
}

export function currentRoute() {
  return router.currentRoute._value.name ?? ""
}

// for examples
export function goToPlanes() {
  router.push({ name: PLANES })
}

export function goToReserva(paseClaseId = null) {
  if (paseClaseId) {
    router.push({
      name: "ReservaPase",
      params: { paseClaseId: paseClaseId, another: "hola" },
    })
  } else {
    router.push({ name: "ReservaPase" })
  }
}

export function getRouterPaseClaseId() {
  const paseClaseId = router.currentRoute._value?.params?.paseClaseId ?? null
  return paseClaseId
}
