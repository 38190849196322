const diasSemana = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]
const diasSemanaLong = ["lunes", "martes", "miercoles", "jueves", "viernes", "sabado", "domingo"]
const diasSemanaLongHtml = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
const mesesLongHtml = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
export const HOY = new Date()

export const arrayOfDates = () => {
  const aodates = [HOY]
  for (let index = 0; index < 6; index++) {
    const date = new Date()
    date.setDate(aodates[index].getDate() + 1)
    aodates.push(date)
  }
  return aodates
}

export const arrayOfStringDates = () => {
  return arrayOfDates().map((d) => {
    const isoDate = d.toISOString().substring(0, 10)
    return { fecha: isoDate, diaSemana: getDayLettersLong(isoDate) }
  })
}

export function getDayLetters(date) {
  const d = new Date(date)
  return diasSemana[d.getDay()]
}

export function getDayLettersLong(date) {
  const d = new Date(date)
  return diasSemanaLong[d.getDay()]
}

export function getDayLettersLongHtml(date) {
  const d = new Date(date)
  return diasSemanaLongHtml[d.getDay()]
}

export function getDay(date) {
  const year = parseInt(date.substr(0, 4))
  const month = parseInt(date.substr(5, 7)) - 1
  const day = parseInt(date.substr(8, 10))
  const d = new Date(year, month, day, 0, 0, 0, 0)

  return d.getDate()
}

export function getMonthLettersLong(mes) {
  return mesesLongHtml[mes - 1]
}

export function getMonthNumber(date = new Date()) {
  const monthNumber = date.getMonth() + 1
  return monthNumber
}

// get 09:32:00 returns 09:32
export function trimHora(hora) {
  return hora.substr(0, 5)
}

// get 04-08-22 returns 04/08
export function formatFecha(fecha) {
  return fecha.replace("-", "/").substr(0, 5)
}

// get 2022-08-04 returns
export function formatFechaDesk(fecha) {
  return `${getDayLettersLongHtml(fecha)} ${getDay(fecha)}`
}

// get 04-08-22 returns a date 2022-08-04
export function createDate(fecha) {
  if (fecha) {
    const fechaString = `20${fecha.substr(6, 2)}-${fecha.substr(3, 2)}-${fecha.substr(0, 2)}`
    // return new Date(fechaString)
    return fechaString
  }
}

// get 04/08/1997 returns 1997-08-04
export function dateToDBFormat(fecha) {
  if (fecha) {
    const [day, month, year] = fecha.split("/")
    return `${year}-${month}-${day}`
  }
  return ''
}

// get 1997-08-04 returns 04/08/1997
export function dateToDisplayFormat(fecha) {
  if (fecha) {
    const [year, month, day] = fecha.split("-")
    return `${day}/${month}/${year}`
  }
}

export const dateToString = (date) => {
  if (!date) {
    return ""
  }
  const day = String(date.getDate()).padStart(2, "0")
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export function isTodayBefore(dateString) {
  const inputDate = new Date(dateString) // Convierte el string a un objeto Date
  const today = new Date() // Obtiene la fecha actual

  // Establece la hora, minutos, segundos y milisegundos a 0 para comparar solo las fechas
  inputDate.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return today < inputDate // Retorna true si la fecha actual es menor que la fecha ingresada
}

export function canCancel(fecha_clase, horario_clase, pase_cancel_limit) {
  let result = true
  if (pase_cancel_limit > 0) {
    const now = new Date().getTime() / 1000 // TODO: ojo acá que uso new date.. y no sé que hace con el GMT
    const year = parseInt(fecha_clase.substr(0, 4))
    const month = parseInt(fecha_clase.substr(5, 7)) - 1
    const day = parseInt(fecha_clase.substr(8, 10))
    const hours = parseInt(horario_clase.substr(0, 2))
    const minutes = parseInt(horario_clase.substr(3, 5))
    const date_clase = new Date(year, month, day, hours, minutes, 0, 0)
    const dt_clase = date_clase.getTime() / 1000
    if (now + pase_cancel_limit > dt_clase) {
      result = false
    }
  }
  return result
}

// ex: dia YYYY-MM-DD | horario HH:mm | offset in seconds
export function horarioMenor(dia, horario, offset = 0) {
  // TODO: acá tal vez también quieran agregar algún límite extra, 2 horas antes no sé
  const offsetTS = offset * 1000

  const dateNow = new Date()
  const timeNow = dateNow.getTime()
  const dateH = new Date(`${dia} ${horario}`)
  const timeH = dateH.getTime()

  if (timeH < timeNow + offsetTS) {
    return true
  }
  return false
}

export function getCurrentDate() {
  const date = new Date()
  const year = date.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).slice(-2) // Months are 0 based, so +1 and pad with leading 0
  const day = ("0" + date.getDate()).slice(-2) // Pad with leading 0

  const formattedDate = `${year}-${month}-${day}`

  return formattedDate
}

export function calcEdad(fecha_nacimiento) {
  if (!fecha_nacimiento) {
    return ""
  }
  const hoy = new Date()
  const [day, month, year] = fecha_nacimiento.split("/")
  const fecha_n = new Date(year, month - 1, day) // Los meses en JavaScript van de 0 a 11, por eso restamos 1.

  let edad = hoy.getFullYear() - fecha_n.getFullYear()
  const diferencia_meses = hoy.getMonth() - fecha_n.getMonth()

  if (diferencia_meses < 0 || (diferencia_meses === 0 && hoy.getDate() < fecha_n.getDate())) {
    edad-- // Si aún no ha llegado al mes de nacimiento o al día exacto, restamos 1 a la edad.
  }

  return edad
}
