<template>
  <v-card class="bg-background mt-8">
    <v-card-title>
      <span class="text-h6">Nuevo Socio</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="formRef">
        <v-container class="pa-4">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="localSocio.nombre" :rules="nameRules" label="Nombre" required :readonly="!editing"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="localSocio.apellido"
                :rules="apellidoRules"
                label="Apellido"
                :readonly="!editing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <MaterialDatePicker v-model="localSocio.fecha_nacimiento" :rules="fechaNacimientoRules" :readonly="!editing"></MaterialDatePicker>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="localSocio.dni" :rules="dniRules" label="Documento" maxlength="8" :readonly="!editing"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field v-model="localSocio.email" :rules="emailRules" label="Correo electrónico" :readonly="!editing"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-maska:[options]="telefonoObject"
                v-model="localSocio.telefono"
                :rules="telefonoRules"
                label="Teléfono"
                :readonly="!editing"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <div class="buttons py-4 pa-sm-6 d-flex justify-space-between">
      <v-btn value="cancel" color="error" @click="cancel">
        <v-icon>mdi-cancel</v-icon>
        Cancelar
      </v-btn>
      <v-btn value="accept" color="primary" @click="saveSocioForm">
        <v-icon>mdi-check</v-icon>
        Aceptar
      </v-btn>
    </div>
  </v-card>
</template>
<script setup>
  import { ref, reactive, watch, computed } from "vue"
  import { vMaska } from "maska"
  import { useToast } from "vue-toastification"
  import { useSociosStore, useEstadosStore } from "@/store"
  import MaterialDatePicker from "@/components/MaterialDatePicker"
  import { OK, goToDashboard, dateToDBFormat, CREANDO_SOCIO } from "@/helpers"

  const props = defineProps({
    dataSocio: {
      type: Object,
    },
  })

  const localSocio = reactive(Object.assign({}, props.dataSocio))

  watch(
    () => props.dataSocio,
    () => {
      Object.assign(localSocio, props.dataSocio)
    }
  )

  const showValidations = ref(false); 
  const serverErrors = reactive({});

  const nameRules = [
    (v) => {
      if (!showValidations.value) return true; // Si showValidations es falso, siempre retorna true porque no queremos validar aún
      if (serverErrors.nombre) {
        const error = serverErrors.nombre
        serverErrors.nombre = ''
        return error
      } // Si hay un error del servidor para 'nombre', lo muestra independientemente de cualquier otra validación y lo limpio
      if (!v) return "El nombre es obligatorio."; // Luego, si el campo está vacío, retorna el mensaje de campo requerido
      if (v.length > 50) return "El nombre no puede tener más de 50 caracteres."; // Finalmente, si el valor es más largo de lo permitido, retorna el mensaje correspondiente
      return true; // Si ninguna condición anterior se cumple, el campo es válido
    },
  ]

  const apellidoRules = [
    (v) => {
      if (!showValidations.value) return true;
      if (serverErrors.apellido) {
        const error = serverErrors.apellido
        serverErrors.apellido = ''
        return error
      } 
      if (!v) return "El apellido es obligatorio."; 
      if (v.length > 50) return "El apellido no puede tener más de 50 caracteres."; 
      return true; 
    },
  ]

  const fechaNacimientoRules = [
    (v) => {
      if (!showValidations.value) return true;
      if (serverErrors.fecha_nacimiento) {
        const error = serverErrors.fecha_nacimiento
        serverErrors.fecha_nacimiento = ''
        return error
      } 
      return true; 
    },
  ]

  // TODO: maybe filter and allow . ex 27.645.444
  const dniRules = [
    (v) => {
      if (!showValidations.value) return true;
      if (serverErrors.dni) {
        const error = serverErrors.dni
        serverErrors.dni = ''
        return error
      } 
      if (!v) return "El DNI es obligatorio."; 
      if (/^\d+$/.test(v)==false) return "El campo sólo debe contener números."
      if (v.length < 7 || v.length > 8) return "El DNI debe tener entre 7 y 8 dígitos."; 
      return true; 
    },
  ]

  const emailRules = [
  
    (v) => {
      if (!showValidations.value) return true;
      if (serverErrors.email) {
        const error = serverErrors.email
        serverErrors.email = ''
        return error
      } 
      if ((v) && (/.+@.+\..+/.test(v)==false)) return "El correo electrónico debe contener un formato válido."
      if (v && v.length > 50) return "El correo electrónico no puede tener más de 50 caracteres."; 
      return true; 
    },
  ]

  const telefonoObject = reactive({})

  const options = reactive({
    mask: "###-#######",
    eager: false,
  })

  const telefonoRules = [
    (v) => {
      if (!showValidations.value) return true;
      if (serverErrors.telefono) {
        const error = serverErrors.telefono
        serverErrors.telefono = ''
        return error
      } 
      if (v && v.length >0 && telefonoObject.unmasked.length != 10) return "El teléfono debe tener exactamente 10 dígitos."
      return true
      // if (telefonoObject.completed) return true
    },
  ]

  const sociosStore = useSociosStore()
  const estadosStore = useEstadosStore()
  const toast = useToast()

  const cancel = () => {
    if (estadosStore.current_operation == CREANDO_SOCIO) {
      goToDashboard()
    }
    estadosStore.setMostrandoSocio()
  }

  const editing = computed(() => {
    return estadosStore.shouldShow.editando || estadosStore.shouldShow.creando_socio
  })


  const formRef = ref(null)
  const saveSocioForm = async () => {
    showValidations.value = false; 
    let socioToSend = { ...localSocio }
    socioToSend.fecha_nacimiento = dateToDBFormat(localSocio.fecha_nacimiento)
    socioToSend.telefono = telefonoObject.unmasked??''

    try {
      const response = await sociosStore.saveSocio(socioToSend)
      if (response.result == OK) {
        Object.keys(serverErrors).forEach(key => serverErrors[key] = ''); // clean errors
        toast.success("Socio guardado correctamente", {
          timeout: 2000,
        })
        if (response.id_socio != 0) {
          sociosStore.selectUnselectSocio(response.id_socio)
        }
        if (estadosStore.current_operation == CREANDO_SOCIO) {
          goToDashboard()
        }
        estadosStore.setMostrandoSocio()
        return true // Retorna true si todo salió bien
      } else if (response.result === false && response.errors){
        Object.assign(serverErrors, response.errors);
        showValidations.value = true;
        formRef.value.validate()
        toast.error("Ups.. hubo algún error", {
          timeout: 2000,
        })
        return false // Retorna false si hubo un error
      }
    } catch (error) {
      showValidations.value = true;
      formRef.value.validate()
      toast.error("Error al guardar el socio", {
        timeout: 2000,
      })
      return false // Retorna false si hubo un error en la promesa
    }
  }

  defineExpose({
    saveSocioForm,
  })
</script>
<style>
  .v-dialog > .v-overlay__content {
    max-width: calc(100% - 24px);
  }

  .buttons {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media only screen and (min-width: 370px) {
    .buttons {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
</style>
