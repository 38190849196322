import { createRouter, createWebHistory } from "vue-router"
import { DASHBOARD, PLANES, SOCIOS, LOGIN, SOCIO_ALTA } from "../helpers"
import { useAuthStore, useEstadosStore } from "@/store"
import Dashboard from "../views/Dashboard.vue"
import VistaPlanes from "../views/Planes.vue"
import Login from "../views/Login.vue"
import Socios from "../views/Socios.vue"
import SocioAlta from "../views/SocioAlta.vue"

const routes = [
  // TODO: check si es mejor ésto, o el login directo en home
  {
    path: "/",
    name: DASHBOARD,
    component: Dashboard,
  },
  {
    path: "/planes",
    name: PLANES,
    component: VistaPlanes,
  },
  {
    path: "/socios",
    name: SOCIOS,
    component: Socios,
    beforeEnter(to, from, next) {
      // by now no extra state needed comes to my mind
      const estadosStore = useEstadosStore()
      estadosStore.setMostrandoSocio()
      next()
    },
  },
  {
    path: "/socios/alta",
    name: SOCIO_ALTA,
    component: SocioAlta,
  },
  {
    path: "/login",
    name: LOGIN,
    component: Login,
  },
  {
    path: "/reestablecer",
    name: "Reestablecer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/PasswordRecovery.vue"),
  },
  {
    path: "/newpassword/:token?",
    name: "Nuevo Password",
    component: () => import(/* webpackChunkName: "about" */ "../views/NewPassword.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  // redirect to login page if user is not logged in and trying to access a restricted page
  const publicPages = [LOGIN, "Reestablecer", "Nuevo Password"]
  const authRequired = !publicPages.includes(to.name)
  // const isLoggedIn = localStorage.getItem("user") // TODO: I think this is wrong -- I should use the vuex store
  const authStore = useAuthStore()
  // const loggedIn = store.state.user
  // const loggedIn = true
  console.log("loggged", authStore.isLoggedIn)
  if (authRequired && !authStore.isLoggedIn) {
    return next("/login")
  }

  next()
})

export default router
