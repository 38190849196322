<template>
  <v-card
    :class="[cuotaStatus, isSelected ? 'selected' : '']"
    class="mt-4 cuota-card"
    max-width="350"
    min-width="280"
    @click="toggleSelect(cuota.id_contrato_cuota)"
  >
    <v-card-title class="justify-center"> Cuota {{ getMonthLettersLong(cuota.mes) }} </v-card-title>
    <v-card-subtitle class="justify-center">Vencimiento: {{ dateToDisplayFormat(cuota.fecha_vencimiento) }}</v-card-subtitle>
    <v-card-text class="d-flex justify-space-between pb-2">
      <span class="text-h6">$ {{ cuota.precio }}</span>
      <v-btn v-show="!isPaid" variant="tonal" :icon="isSelected ? 'mdi-check-circle' : 'mdi-circle-outline'"></v-btn>
    </v-card-text>
  </v-card>
</template>

<script setup>
  // TODO: analyze if should let the user select the last cuota instead of the first one.. , maybe last one only if current its already paid
  import { computed, ref } from "vue"
  import { useCuotasStore } from "@/store"
  import { getMonthLettersLong, CuotasEstado, dateToDisplayFormat, isTodayBefore } from "@/helpers"

  const props = defineProps(["cuota"])

  const isPaid = computed(() => {
    return props.cuota.estado == CuotasEstado.PAGO
  })

  const cuotaStatus = computed(() => {
    return isPaid.value ? "paid-gradient" : props.cuota.es_ultima ? "notyet-gradient" : "unpaid-gradient"
  })

  const cuotasStore = useCuotasStore()

  const isSelected = computed(() => {
    return props.cuota.id_contrato_cuota == cuotasStore.cuota_selected_id
  })

  const toggleSelect = (cuota_id) => {
    if (isPaid.value) {
      return
    }
    if (cuotasStore.cuota_selected_id == cuota_id) {
      cuotasStore.selectUnselectCuota(null)
      return
    }
    cuotasStore.selectUnselectCuota(cuota_id)
  }
</script>

<style scoped>
  .v-card-text span {
    line-height: 48px;
  }
  /* Estilo para cuota paga */
  .paid-gradient {
    background: linear-gradient(135deg, hsl(122, 39%, 25%), hsl(122, 39%, 49%));
  }

  /* Estilo para cuota impaga con gradiente de oscuro a rojo */

  .unpaid-gradient {
    background: linear-gradient(135deg, hsl(4, 90%, 25%), hsl(4, 90%, 58%));
  }

  .notyet-gradient {
    background: linear-gradient(135deg, hsl(20, 5%, 25%), hsl(20, 5%, 58%));
  }

  /* Estilo para cuota impaga con gradiente de transparente a rojo (comentado) */
  /*.unpaid-gradient {
    background: linear-gradient(15deg, transparent, hsl(4, 90%, 58%));
  } */

  .cuota-card {
    width: 100%;
    justify-self: center;
    box-shadow: none; /* Sin sombra */
    transform: scale(1); /* Tamaño original */
    transition: transform 0.2s ease, border-color 0.2s ease; /* Transición suave al deseleccionar */
  }
  .selected {
    /* border: 3px solid #fff3cd; /* Borde claro */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    transform: scale(1.02); /* Un ligero aumento en el tamaño para dar un efecto de elevación */
    transition: transform 0.2s ease; /* Transición suave al seleccionar */
  }
</style>
