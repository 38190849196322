export function formatCurrency(value) {
  // Convertir el string a número
  let numberValue = parseFloat(value)

  // Formatear el número a moneda
  let formattedValue = numberValue.toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formattedValue
}

export function formatTelefono(numero) {
  if (!numero) return "-"
  // Asegúrate de que el número es una cadena
  let numeroStr = numero.toString()

  // Agrega el guion después de los primeros 3 dígitos
  numeroStr = numeroStr.slice(0, 3) + "-" + numeroStr.slice(3)
  if (numeroStr.length != 11) return "-"
  return numeroStr
}

/* 
  recieves a string in formated as "1015"
  returns "10:15"
  or returns "-"
*/
export function formatHora(hora) {
  if (!hora) return "-"
  let horaStr = hora.toString()
  horaStr = horaStr.slice(0, 2) + ":" + horaStr.slice(2)
  if (horaStr.length!= 5) return "-"
  return horaStr
}


// transforma la cadena a su forma de descomposición normalizada, removiendo comas "," y separando las letras de las marcas diacríticas (como tildes).
export function normalizeString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") 
    .replace(/,/g, "") 
    .toLowerCase(); 
}
