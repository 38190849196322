<template>
  <v-app>
    <NavBar />
    <v-main class="py-16 bg-background">
      <div class="px-sm-4 app-container">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script setup>
  import { onMounted } from "vue"
  import NavBar from "./components/NavBar.vue"
  import update from "./mixins/update"

  onMounted(() => {
    if (update.data().updateExists) {
      update.methods.refreshApp()
    }
  })
</script>
<style scoped>
  .app-container {
    height: 100%;
  }
</style>
